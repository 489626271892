var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","app":""}},[_c('page-title',{attrs:{"title":"Digital Ecosystem","useBackBtn":true}}),_c('div',{staticClass:"flex justify-content-center padding",staticStyle:{"padding-top":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-subheader',[_vm._v("Institutions benefits from Projects")])],1)],1)],1),_c('div',{staticClass:"d-flex col justify-end align-center right"},[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"title":"","right":"","fab":"","small":"","color":"success white--text"},on:{"click":function($event){return _vm.addProjectDialog(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add New Institution")])])],1),_c('v-row',{staticClass:"mt-5"},[_c('v-card',{staticClass:"col-md-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+"% ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{key:item.name,attrs:{"offset-y":"","app":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":"","color":"success"},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":"/components/project_details"}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-pencil-plus-outline")]),_vm._v(" Edit Institution")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"},attrs:{"color":"pink"}},[_vm._v("mdi-delete")]),_vm._v(" Delete")],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }